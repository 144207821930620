import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';

const FiveZeroZero = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about" className="about-error">
      <Container>
        <Title title="Where were you going?" />
        <Row className="about-wrapper">
          <Col>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  Oops! Something went wrong on this end, please try again in a few minutes.
                </p>
                <span className="mt-5">
                  <a
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    href="https://www.marienwolthuis.nl"
                  >
                    Back to the site
                  </a>
                </span>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FiveZeroZero;
